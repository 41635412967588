import { withEmotionCache } from '@emotion/react';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  json,
  type SerializeFrom,
  type DataFunctionArgs,
  type HeadersFunction } from
'@remix-run/node';
import { withSentry } from '@sentry/remix';
import bootstrapStyles from 'bootstrap/dist/css/bootstrap.css';
import { Suspense, lazy, useEffect, useRef, useContext } from 'react';
import { SSRProvider } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import toastStyles from 'react-toastify/dist/ReactToastify.css';
import TopBarProgress from 'react-topbar-progress-indicator';
import {
  useGlobalLoadingState,
  useGlobalSubmittingState,
  useHydrated } from
'remix-utils';
import { useSpinDelay } from 'spin-delay';
import {
  Links,
  type LinksFunction,
  LiveReload,
  Meta,
  type MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
'~/remix.ts';
import ClientStyleContext from '~/styles/client.context.tsx';
import ServerStyleContext from '~/styles/server.context.tsx';
import {
  getPublicKeys,
  PublicEnv,
  type PublicKeysProps } from
'~/utils/public-env.tsx';
import theme from '../app/assets/css/theme.css';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { UnderConstruction } from './components/under-construction.tsx';
import {
  authRequestInterceptor,
  authResponseInterceptor,
  getRoles
  // checkifUnderConstruction
} from './services/kiz-core-engine.server.ts';
import {
  type AuthUser,
  getUser,
  logout,
  getRedirectPath } from
'./sessions/auth.server.ts';
import { getToast, getToastSession } from './sessions/toast.server.ts';
import { type RoleResponse } from './types/kiz-core.ts';
import { ClientHintCheck, getHints } from './utils/client-hints.tsx';
import { useEventListeners } from './utils/event-listeners.tsx';
import { logger } from './utils/logger.ts';
import { combineHeaders, getDomainUrl } from './utils/misc.ts';
import { useNonce } from './utils/nonce-provider.ts';
import { AbilityProvider, createAbility } from './utils/permissions.ts';
import { makeTimings, time } from './utils/timing.server.ts';
import {
  type ToastId,
  dismissToast,
  loadingNotify,
  notify,
  toastPositions,
  useUserToast } from
'./utils/toasts.tsx';
// import { createAuthorizationToken } from "./utils/encryption.server.ts"

const RemixDevTools =
process.env.NODE_ENV === 'development' ?
lazy(() => import('remix-development-tools')) :
null;

// @ts-ignore
TopBarProgress.config({
  barColors: {
    0: '#000',
    '1.0': '#000'
  },
  shadowBlur: 5
});

export const links: LinksFunction = () => [
...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : []),
// { rel: 'styleheet', ref: iconifyCss },
{ rel: 'stylesheet', href: bootstrapStyles },
{ rel: 'stylesheet', href: toastStyles },
{ rel: 'stylesheet', href: theme }];


export const meta: MetaFunction = () => {
  return [
  {
    title: 'KIC Portal'
  },
  {
    name: 'description',
    content: 'KIC EasyCover system'
  }
  // {
  // 'msapplication-TileColor': '#171717'
  // },
  // {
  // 'theme-color': '#ffffff'
  // },
  ];
};

export async function loader({ request }: DataFunctionArgs) {
  const timings = makeTimings('root loader');
  const user = await time(() => getUser(request), {
    timings,
    type: 'getUser',
    desc: 'getUser in root loader'
  });

  if (user) {
    authRequestInterceptor(user.authToken, user.client, user.key);
  }

  const [
  { createToastSession },
  { toast: headerToast, headers: toastHeaders }] =
  await Promise.all([getToastSession(request), getToast(request)]);
  const { headers: defaultToastHeaders, toastId } = await createToastSession();
  const path = getRedirectPath(request);
  let unauthed = false;
  const responseInterceptor = authResponseInterceptor(path, !!user, unauthed);
  unauthed = responseInterceptor.unauthed;

  if (unauthed) {
    return await logout(request, path);
  }

  const roles = user ?
  (await getRoles(timings)).map((r) => ({ id: r.id, name: r.name })) : (
  [] as Array<RoleResponse>);
  const isUnderConstruction = false; // await checkifUnderConstruction()

  let themeClass = 'bg-theme';

  let userDepartment = user ? user.department : 'other';

  if (userDepartment === 'insurance') {
    themeClass = 'bg-blue';
  } else if (userDepartment === 'reinsurance') {
    themeClass = 'bg-pink';
  } else if (userDepartment === 'sureties') {
    themeClass = 'bg-yellow';
  } else {
    themeClass = 'bg-theme';
  }

  return json(
    {
      user,
      toastId,
      headerToast,
      roles,
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {

          // theme: getTheme(request)
        } },
      ...getPublicKeys(),
      isUnderConstruction,
      themeClass
    },
    {
      headers: combineHeaders(
        {
          'Server-Timing': timings.toString()
        },
        defaultToastHeaders,
        toastHeaders
      )
    }
  );
}
export type RootLoaderData = SerializeFrom<typeof loader>;

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

const Document = withEmotionCache(
  (
  {
    children,
    nonce,
    theme = 'light',
    env





  }: {children: React.ReactNode;nonce: string;theme?: 'dark' | 'light';env?: PublicKeysProps;},
  emotionCache) =>
  {
    const loadingToast = useRef<ToastId>();
    const submittingToast = useRef<ToastId>();
    const loading = useGlobalLoadingState();
    const isLoading = useSpinDelay(loading === 'loading', {
      delay: 500,
      minDuration: 200
    });
    const submitting = useGlobalSubmittingState();
    const isSubmitting = useSpinDelay(submitting === 'submitting', {
      delay: 500,
      minDuration: 300
    });

    useEffect(() => {
      if (isLoading) {
        loadingToast.current = loadingNotify('Loading...');
      } else if (!isLoading && loadingToast.current) {
        dismissToast(loadingToast.current);
      }
    }, [isLoading]);

    useEffect(() => {
      if (isSubmitting) {
        submittingToast.current = loadingNotify('Submitting form...');
      } else if (!isSubmitting && submittingToast.current) {
        dismissToast(submittingToast.current);
      }
    }, [isSubmitting]);

    // Emotion
    const serverStyleData = useContext(ServerStyleContext);
    const clientStyleData = useContext(ClientStyleContext);
    const reinjectStylesRef = useRef(true);
    // Only executed on client
    // When a top level ErrorBoundary or CatchBoundary are rendered,
    // the document head gets removed, so we have to create the style tags
    useEffect(() => {
      if (!reinjectStylesRef.current) {
        return;
      }
      // re-link sheet container
      emotionCache.sheet.container = document.head;

      // re-inject tags
      const tags = emotionCache.sheet.tags;
      emotionCache.sheet.flush();
      tags.forEach((tag) => {
        ;(emotionCache.sheet as any)._insertTag(tag);
      });

      // reset cache to re-apply global styles
      clientStyleData.reset();
      // ensure we only do this once per mount
      reinjectStylesRef.current = false;
    }, [clientStyleData, emotionCache.sheet]);

    return (
      <html lang="en" className={`h-100`}>
				<head>
					<ClientHintCheck nonce={nonce} />
					<Meta />
					<meta charSet="utf-8" />
					<meta name="viewport" content="width=device-width,initial-scale=1" />
					<meta name="robots" content="noindex,nofollow" />
					<Links />
					{serverStyleData?.map(({ key, ids, css }) =>
          <style
            key={key}
            data-emotion={`${key} ${ids.join(' ')}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: css }} />

          )}
				</head>
				<body className="min-vh-100">
					{/* @ts-ignore */}
					{loading === 'loading' && <TopBarProgress />}

					{children}

					<ToastContainer theme="dark" autoClose={30_000} />

					{env ? <PublicEnv {...env} /> : null}
					<ScrollRestoration
            nonce={nonce}
            getKey={(location) => {
              const paths = [
              '/dashboard/quotations/create',
              '/uploads/inspection-tool'];

              return paths.some((path) => location.pathname.includes(path)) ?
              location.pathname :
              location.key;
            }} />

					<Scripts nonce={nonce} />
					<LiveReload nonce={nonce} />
				</body>
			</html>);

  }
);

function App() {
  const {
    publicKeys,
    toastId,
    headerToast,
    user,
    isUnderConstruction,
    userDepartment
  } = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const isHydrated = useHydrated();
  useEventListeners();
  useUserToast(toastId, isHydrated);

  logger.debug('User is hydrated');

  // const AbilityContext = create

  useEffect(() => {
    if (isHydrated) {
      if (headerToast) {
        notify(headerToast.description, {
          type: headerToast.type,
          autoClose: headerToast.duration,
          toastId: headerToast.id
        });
      }

      // if (!userToast) {
      // 	return
      // }

      // const toast = parseToast(userToast)
      // if (toast.createdAt !== toastTime.current) {
      // 	toastTime.current = toast.createdAt
      // 	notify(toast.message, {
      // 		...toast.options,
      // 		toastId: `/events/toasts/${toast.createdAt}`,
      // 	})
      // }
    }
  }, [isHydrated, headerToast]);

  if (isUnderConstruction) {
    return (
      <Document nonce={nonce} env={publicKeys}>
				<UnderConstruction />
			</Document>);

  } else if (user) {
    const ability = createAbility((user as AuthUser).permissions);

    return (
      <AbilityProvider value={ability}>
				<Document nonce={nonce} env={publicKeys}>
					<Outlet />
					{RemixDevTools ?
          <Suspense>
							<RemixDevTools />
						</Suspense> :
          null}
				</Document>
			</AbilityProvider>);

  }

  return (
    <Document nonce={nonce} env={publicKeys}>
			<Outlet />
		</Document>);

}

function SSRApp() {
  return (
    <SSRProvider>
			<App />
		</SSRProvider>);

}

export default withSentry(SSRApp);

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <SSRProvider>
			<Document nonce={nonce}>
				<GeneralErrorBoundary />
			</Document>
		</SSRProvider>);

}